.search-form {
    margin-right: 32px;
}

.search-field {
	background-image: url(https://wp-themes.com/wp-content/themes/twentythirteen/images/search-icon.png);
	background-position: 5px center;
	background-repeat: no-repeat;
	background-size: 24px 24px;
	border: none;
	cursor: pointer;
	height: 34px;
	margin: 3px 0;
	padding: 0 0 0 34px;
	position: relative;
	-webkit-transition: width 400ms ease, background 400ms ease;
	transition: width 400ms ease, background 400ms ease;
	width:0px;
    cursor: pointer;
}

.search-field:focus {
	border: 2px solid #c3c0ab;
	cursor: text;
	outline: 0;
	width: 250px;
    color:black;
    border-radius:8px;
}
