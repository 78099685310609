.slick-slider {
    max-width: 1440px;
    margin: 0 auto 72px auto;
}

.slick-list{
    max-height: 740px;
}

.app-slider-content > img{
    max-height: 740px;
    min-height: 350px;
}

.app-slider-content::before{
    content: '';
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.55);
}

.slick-slide img{
    width: 100%;
    height: 740px;
    object-fit: cover;

}

.slick-dots{
    bottom: 30px;
}

.slick-dots li.slick-active button:before{
    color: #4FC6DF;
}

.slick-dots li button:before{
    font-size: 12px;
    color: #4FC6DF;
}

.app-slider-content{
    position: relative;
}

.text-block{
    position: absolute; 
    top: 50%;
    padding-left: 72px;
    padding-right: 72px;
    transform: translateY(-50%);

}
@media (max-width:768px) {
    .text-block{
        width: 76%;
        padding: 0% 12%;
        align-items: center;
    }

}
