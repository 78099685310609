@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

* {
    font-family: "Noto Sans Armenian", sans-serif !important;
}

body {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

.bg-body {
    filter: invert(0.1) contrast(100%) brightness(150%) grayscale(100%);
}
